import api from "./api";

const req = {
  getAll: async (id: string, start_date: string, end_date: string, range: string) => {
    const response = await api.get(`/chart/rentabilidade?start_date=${start_date}&end_date=${end_date}&wallet_id=${id}&range=${range}`);

    return response;
  }
};

export default req;
