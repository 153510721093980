import { Card, createStyles, MediaQuery,Button,
  Center,
  Chip,
  Flex,
  Grid,
  Group,
  Modal,
  Text,
  Title, } from "@mantine/core";
  import { DatePicker } from "@mantine/dates";
import {
  
} from "@mantine/core";
import "moment-timezone";
import "moment/locale/pt-br";
import { useState, useEffect } from "react";
import graficoRentabilidadeService from "../../../../services/graficoRentabilidade";
import { IconCalendarEvent } from "@tabler/icons";
import "moment-timezone";
import "moment/locale/pt-br";
import Moment from "react-moment";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import moment from 'moment';

export default function ChartRentabilidade() {
  const { id } = useParams();

  const [data, setData] = useState<any>([]);
  // Datas para filtro
  const [filterStartDate, setFilterStartDate] = useState<string | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<string | null>(null);
  // Datas retornadas pela API
  const [apiStartDate, setApiStartDate] = useState<string | null>(null);
  const [apiEndDate, setApiEndDate] = useState<string | null>(null);

  const [range, setRange] = useState<string | null>(null);

  // Apenas o fetch inicial
  useEffect(() => {
    fetchData();
  }, []); // Roda apenas na montagem do componente

  useEffect(() => {
    console.log('Dados atualizados:', data);
  }, [data]);
  
  const fetchDataAll = () => {
    fetchData('all')
  }

  const fetchData = async (range?: string) => {
    try {
      const formattedStartDate = filterStartDate ? moment(filterStartDate).format('DD-MM-YYYY') : '';
      const formattedEndDate = filterEndDate ? moment(filterEndDate).format('DD-MM-YYYY') : '';

      const response = await graficoRentabilidadeService.getAll(
        id ? id : '', 
        formattedStartDate,
        formattedEndDate,
        range || ''
      );

      const initialCDI = response.data.cdi[0].cota;
      const initialWallet = response.data.wallet[0].cota;

      setApiStartDate(response.data.start_date);
      setApiEndDate(response.data.end_date);
      
      const formattedData = response.data.cdi.map((cdiItem: any, index: number) => ({
        date: cdiItem.data,
        cdi: Number((((cdiItem.cota - initialCDI) / initialCDI) * 100).toFixed(2)),
        wallet: Number((((response.data.wallet[index].cota - initialWallet) / initialWallet) * 100).toFixed(2))
      }));

      setData(formattedData);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  

  const [opened, { close, open }] = useDisclosure(false);
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "white",
            width: 150,
            display: "flex",
            flexDirection: "column",
            margin: 0,
            borderRadius: 10,
            fontWeight: 300,
            padding: 10,
            border: "none",
            fontSize: 12,
            outline: "none",
            boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px"
          }}
          className="custom-tooltip"
        >
          {label}
          <b>{` CDI: ${payload[0].value.toFixed(2)}%`}</b>
          <b>{` Carteira: ${payload[1].value.toFixed(2)}%`}</b>
          <b>{` Carteira / CDI: ${(
            (payload[1].value / payload[0].value) *
            100
          ).toFixed(2)}%`}</b>
        </div>
      );
    }

    return null;
  };

    const useStyles = createStyles((theme, _params, getRef) => ({
      modal: {
        display: "flex",
        flexDirection: "column",
      },
      bodyText: {
        color: theme.colors.dark[2],
        marginLeft: 7,
      },
      titlePage: {
        fontSize: 26,
        fontWeight: 500,
        color: theme.colors.blue[6],
        [theme.fn.smallerThan("sm")]: {
          fontSize: 20,
        },
  
        "&::after": {
          content: '""',
          display: "block",
          backgroundColor: theme.colors.blue[6],
          width: 100,
          height: 2,
          marginBottom: 15,
        },
      },
      title: {
        fontSize: 34,
        fontWeight: 900,
        [theme.fn.smallerThan("sm")]: {
          fontSize: 24,
        },
      },
      select: {
        [theme.fn.smallerThan("sm")]: {
          width: "160px",
        },
      },
  
      top: {
        justifyContent: "space-between",
        padding: "5px 0px",
  
        [theme.fn.smallerThan("sm")]: {
          justifyContent: "space-between",
          marginBottom: 10,
          padding: "0px",
        },
      },
      root: {
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.xs,
        border: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[1]
        }`,
      },
  
      active: {
        backgroundImage: theme.fn.gradient({
          from: "blue",
          to: "blue",
        }),
      },
  
      control: {
        border: "0 !important",
      },
  
      labelActive: {
        color: `${theme.white} !important`,
      },
  
      description: {
        maxWidth: 600,
        margin: "auto",
  
        "&::after": {
          content: '""',
          display: "block",
          backgroundColor: theme.fn.primaryColor(),
          width: 45,
          height: 2,
          marginTop: theme.spacing.sm,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
  
      card: {
        border: `1px solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[5]
            : theme.colors.gray[1]
        }`,
        display: "flex",
        flexDirection: "column",
      },
  
      cardTitle: {
        "&::after": {
          content: '""',
          display: "block",
          backgroundColor: theme.fn.primaryColor(),
          width: 75,
          height: 2,
          marginTop: theme.spacing.xs,
        },
      },
      label: {
        "&[data-checked]": {
          "&, &:hover": {
            backgroundColor: theme.colors.blue[theme.fn.primaryShade()],
            color: theme.white,
            border: "none",
            boxShadow: "none",
          },
  
          [`& .${getRef("iconWrapper")}`]: {
            color: theme.white,
          },
        },
      },
  
      text: {
        fontWeight: 500,
        margin: 0,
        color: theme.colors.dark[2],
        [theme.fn.smallerThan("sm")]: {
          fontSize: 10,
        },
      },
  
      iconWrapper: {
        ref: getRef("iconWrapper"),
      },
    }));
    const { classes, theme } = useStyles();

  
    
  return (
    <Card shadow="xs" className={classes.card}>
         <Modal
                    opened={opened}
                    onClose={close}
                    size="auto"
                    title="Personalizar Data"
                  >
                    <Group mt="xl" className={classes.modal}>
                      <DatePicker
                        fullWidth
                        onChange={(e:any) => setFilterStartDate(e)}
                        placeholder="Data Inicial"
                        withAsterisk
                      />
                      <DatePicker
                        fullWidth
                        onChange={(e:any) => setFilterEndDate(e)}
                        placeholder="Data Final"
                        withAsterisk
                      />
                      <Button
                        fullWidth
                        variant="outline"
                        onClick={() => {
                          fetchData();
                          close();
                        }}
                      >
                        Aplicar
                      </Button>
                    </Group>
                  </Modal>
                 <Group className={classes.top}>
                    <Text size="lg" className={classes.cardTitle}>
                      {"CDI x Carteira"}
                    </Text>
                    <Flex direction={"column"}>
                      <Flex direction={"row"}>
                        <IconCalendarEvent
                          size={16}
                          stroke={2.5}
                          color={theme.colors.dark[2]}
                        />
                        <Text size="sm" className={classes.bodyText}>
                          <Moment>{apiStartDate?.toString()}</Moment>
                        </Text>
                      </Flex>

                      <Flex direction={"row"}>
                        <IconCalendarEvent
                          size={16}
                          stroke={2.5}
                          color={theme.colors.dark[2]}
                        />
                        <Text size="sm" className={classes.bodyText}>
                          <Moment>{apiEndDate?.toString()}</Moment>
                        </Text>
                      </Flex>
                    </Flex>
                    <Chip.Group
                      defaultValue="tudo"
                      position="center"
                      multiple={false}
                    >
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                         onClick={() => fetchDataAll()}
                        value="tudo"
                      >
                        TUDO
                      </Chip>
                      {/* <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        // onClick={() => handleChangeDateChips(1)}
                        value="react"
                      >
                        1 ANO
                      </Chip>
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        // onClick={() => handleChangeDateChips(2)}
                        value="ng"
                      >
                        2 ANOS
                      </Chip> */}
                      <Chip
                        size="xs"
                        classNames={classes}
                        style={{
                          background: "transparent",
                          boxShadow: "none",
                          border: "none",
                        }}
                        onClick={open}
                        value="personalizado"
                      >
                        PERSONALIZADO
                      </Chip>
                    </Chip.Group>
                  </Group>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={400}>
            <LineChart
              width={400}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: -20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0.8 5" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 11, fontWeight: 200 }}
                
              />
              <YAxis
                domain={['auto', 'auto']}
                tick={{ fontSize: 10, fontWeight: 200 }}
                tickFormatter={(value) => `${value}%`}
              />

              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />
              <Legend />
              <Line
                strokeWidth={2.5}
                type="monotone"
                dataKey="cdi"
                dot={false}
                stroke="#fd7e14"
                activeDot={{ r: 5 }}
              />
              <Line
                strokeWidth={1.5}
                type="monotone"
                dataKey="wallet"
                name={'carteira'}
                dot={false}
                stroke="#928EDB"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MediaQuery>

        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: -30,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="0.8 5" />
              <XAxis
                dataKey="date"
                tick={{ fontSize: 11, fontWeight: 200 }}
              />
              <YAxis
                domain={['auto', 'auto']}
                tick={{ fontSize: 10, fontWeight: 200 }}
              />

              <Tooltip
                wrapperStyle={{ outline: "none" }}
                content={<CustomTooltip />}
              />
              <Legend />
              <Line
                strokeWidth={2.5}
                type="monotone"
                dataKey="cdi"
                dot={false}
                stroke="#fd7e14"
                activeDot={{ r: 5 }}
              />
              <Line
                strokeWidth={1.5}
                type="monotone"
                dataKey="wallet"
                dot={false}
                stroke="#928EDB"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MediaQuery>
    </Card>

  );
}

