import { Flex, Grid, SimpleGrid } from "@mantine/core";
import { useParams } from "react-router-dom";
import { MutableInfoCarteira } from "../../../components/Carteira/MutableInfo";
import { StaticInfoCarteira } from "../../../components/Carteira/StaticInfoCarteira";
import TableAdmEscalonada from "../../../components/Carteira/TableAdmEscalonada";
import TablePfeeDate from "../../../components/Carteira/TablePfeeDate";
import { HeaderCarteira } from "../../../components/Carteira/TopCarteiraInfo";
import { TitlePage } from "../../../components/TitlePage";
import { ButtonToggleMenuOptions } from "./ButtonToggleMenuOptions";
import ImportarDadosModal from "./ImportDadosModal";
import RetroagirCarteiraModal from "./RetroagirCarteiraModal";
import MovManualClienteModal from "./MovManualClienteModal";
import ProcesssarCarteirasModal from "./ProcessarCarteiraClienteModal";
import ProcesssarCarteiraClienteModal from "./ProcessarCarteiraClienteModal";
import TableMovimentacaoManualCarteira from "../../../components/Carteira/TableMovimenManual";
import ChartRentabilidade from "./ChartRentabilidade";

export default function DetalheCarteira() {
  const { id } = useParams();

  return (
    <>
      <Flex align={"left"}>
        <TitlePage title="Carteira Detalhada" />
      </Flex>
      
      <SimpleGrid cols={1} pb={"xs"}>
        <Flex justify={"end"} gap="xs" align={"center"}>
          <ImportarDadosModal />
          <RetroagirCarteiraModal />
          <MovManualClienteModal refetch={() => {}} carteira={id ? id : ''} />
          <ProcesssarCarteiraClienteModal carteira={id ? id : ''} />
          <ButtonToggleMenuOptions></ButtonToggleMenuOptions>
          
        </Flex>
      </SimpleGrid>
      <Grid align={"stretch"}>      

        <Grid.Col md={12} lg={8}>
          <HeaderCarteira id={id ? id : ""}></HeaderCarteira>
        </Grid.Col>

        <Grid.Col md={12} lg={4}>
          <StaticInfoCarteira id={id ? id : ""}></StaticInfoCarteira>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <ChartRentabilidade></ChartRentabilidade>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <MutableInfoCarteira id={id ? id : ""}></MutableInfoCarteira>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <TableAdmEscalonada id={id ? id : ""}></TableAdmEscalonada>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <TablePfeeDate id={id ? id : ""}></TablePfeeDate>
        </Grid.Col>

        <Grid.Col md={12} lg={12}>
          <TableMovimentacaoManualCarteira></TableMovimentacaoManualCarteira>
        </Grid.Col>

        
      </Grid>
    </>
  );
}
